import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchSummaryGraphs } from '../../api/summaryApi';
import originalMoment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(originalMoment);

const initialState = {
	groupData: [],
	categoryData: [],
	periodStart: '2025-01-01',
	perioEnd: '2025-01-31',
	loading: false,
	loadingSave: false
}

export const getSummaryGraph = createAsyncThunk(
	'summary/getGraph',
	async (periodChanged = false, thunkAPI) => {
		let start = null;
		let end = null;

		if(!periodChanged){
			start = moment().subtract(12, 'months').startOf('month').format('YYYY-MM-DD');
			end = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
		}else{
			const state = thunkAPI.getState().summary;
			start = state.periodStart;
			end = state.perioEnd;
		}
		
		const data = await fetchSummaryGraphs({start, end});
		return {...data, start, end};
	}
)

const summarySlice = createSlice({
	name: "summarySlice",
	initialState,
	reducers: {
		handleSelectDate: (state, action) => {
			const { start, end } = action.payload
			if (start !== undefined) {
				if (moment(start).isBefore(state.perioEnd)) state.periodStart = start;
			}
			if (end !== undefined) {
				if (moment(end).isAfter(state.periodStart)) state.perioEnd = end;
			}
		},
		handleSelectPeriod: (state, action) => {
			const { start, end } = action.payload
			state.periodStart = start;
			state.perioEnd = end;
			console.log(start, end)
		},
		setPeriodLabel: (state, action) => {
			state.periodLabel = action.payload
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getSummaryGraph.pending, (state) => {
				state.groupData = []
				state.categoryData = []
				state.loading = true
			})
			.addCase(getSummaryGraph.fulfilled, (state, action) => {
				state.groupData = action.payload.groupData
				state.categoryData = action.payload.categoryData
				state.loading = false
				state.periodStart = action.payload.start;
				state.perioEnd = action.payload.end;
			})
			.addCase(getSummaryGraph.rejected, (state, action) => {
				state.users = []
				state.loading = false
			})
	}
})

export const summarySelector = state => state.summary;
export const { handleSelectDate, handleSelectPeriod, setPeriodLabel } = summarySlice.actions
export default summarySlice.reducer;