import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleSelectDate, setPeriodLabel, summarySelector, getSummaryGraph } from '../../store/slices/summarySlice';
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { Button as MuiButton, Grid, Menu, styled, IconButton, Typography } from "@mui/material";
import Datepicker from "./Datepicker";
// import { tenantSelector } from '../../App/tenantInfoSlice';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';

const moment = extendMoment(originalMoment);

const selectablePeriodLabel = "El rango máximo de consulta es de un año móvil"


const Button = styled(MuiButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: 'red'
  },
  "&.react-datepicker__month":{
    backgroundColor:'red'
  },
  '&:hover, &.Mui-focusVisible': {
    color: "white",
    backgroundColor: '#2d2d6590'
  },
});


const DropdownDatepicker = () => {
  const dispatch = useDispatch();
  const { periodStart: start, perioEnd: end} = useSelector(summarySelector);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [displayUpdate, setDisplayUpdate] = useState(true);
  // const { colorsPrimary, colorsTextPrimary, colorsBGSecondary, colorsSecondary } = useSelector(tenantSelector);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setDisplayUpdate(true);
  };

  const handleUpdateButton = () => {
    handleClose();
    dispatch(setPeriodLabel(''));
    dispatch(getSummaryGraph(true));
  }

  const setStartDate = date => {
    const start = moment(date).format("YYYY-MM-DD");
    dispatch(handleSelectDate({ start }))
  }

  const setEndDate = date => {
    const end = moment(date).format("YYYY-MM-DD");
    dispatch(handleSelectDate({ end }))
  }

  const handleEndDatepicker = date => {
    setEndDate(date)
    setDisplayUpdate(false);
  }

  return (
    <div>
      <Grid container justifyContent={"center"} alignItems={"center"}>      
        <Button          
          variant="contained"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{
            // bgcolor: colorsBGSecondary, 
            color: '#fff', height: '25px', fontSize: '12px',
            "&.Mui-selected, &.Mui-selected:hover": {
              color: "white",
              // backgroundColor: colorsBGSecondary
            },
            '&:hover, &.Mui-focusVisible': {
              color: "white",
              // backgroundColor: colorsBGSecondary
            },
            pt: 1
          }}
        >
          {moment(start).format('DD-MM-YYYY[ / ]') + moment(end).format('DD-MM-YYYY')}               
        </Button>
        <IconButton size="small" onClick={handleClick} sx={{pr: 0}}>
          <CalendarMonthRoundedIcon />
        </IconButton>        
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <div style={{ margin: '0px 15px 0px 15px' }}>
          <Grid container
            direction="column"
            alignItems="center"
            justifyContent="center"
            mr={0}
          >
            <Grid container justifyContent="space-around">
              <Datepicker title={"Desde"} value={start} start={start} end={end} setDate={setStartDate} />
              <Datepicker title={"Hasta"} value={end} start={start} end={end} setDate={handleEndDatepicker} lastDateOfMonth={true} />
            </Grid>

            <Grid container justifyContent="space-around" alignItems={"center"}>
              <Typography align="center" width={'50%'} sx={{
                fontWeight: "400",
                fontSize: "12px",
                color: "#9292C1"                
              }}>{selectablePeriodLabel}</Typography>
              <Button
                disabled={displayUpdate}
                onClick={handleUpdateButton}
                variant="contained" size="small"
                sx={{
                  // bgcolor: colorsPrimary,
                  color: '#fff',
                  height: '25px',
                  fontSize: '10px'
                }}
              >Actualizar</Button>
            </Grid>
          </Grid>

        </div>
      </Menu>
    </div>
  )
}

export default DropdownDatepicker;