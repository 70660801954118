import "react-datepicker/dist/react-datepicker.css";
import './style.css';
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import moment from "moment";
import { Typography } from "@mui/material";

registerLocale("es", es);
const monthsToSelect = moment().clone().subtract(12, 'month').format("YYYY-MM-DD");
const maxDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');

const CustomDatepicker = ({ title, value, start, end, setDate, lastDateOfMonth = false }) => {

  const transformDate = date => {
    const newDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    return lastDateOfMonth ? setDate(newDate) : setDate(date)
  }

  return (
    <div>      
      <Typography sx={{fontWeight: "700", fontSize:12, color:"#5A5A89"}} align="left">{title}</Typography>
      <DatePicker
        wrapperClassName='datePicker'
        locale="es"
        selected={moment(value).toDate()}
        // onChange={date => transformDate(date)}
        onSelect={date => transformDate(date)}
        selectsStart
        startDate={moment(start).toDate()}
        endDate={moment(end).toDate()}
        dateFormat="dd/MM/yyyy"
        showMonthYearPicker
        showFullMonthYearPicker
        showTwoColumnMonthYearPicker
        inline
        minDate={new Date(monthsToSelect)}
        maxDate={new Date(maxDate)}
        showDisabledMonthNavigation
      />
    </div>

  );
};

export default CustomDatepicker;